/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://wgkssn2xvbgr5buaqshnqm7lmm.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7pselzz545cgtdia6pxmgx57bq",
    "aws_cognito_identity_pool_id": "eu-north-1:08c6bb5d-8abb-4edc-9861-93e807781aa2",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_yLiXq078c",
    "aws_user_pools_web_client_id": "7n3e418kgbr3rlv7s33sjf48a3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
